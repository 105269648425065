import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from './components/taxonDB/attributesStructure'

export const TAXONDB_DETAILS_REQUEST_ID_PREFIX = 'taxondb-details-'
export const TAXONDB_DETAILS_REQUEST_URL = '/taxons/details/'
export const TAXONDB_DETAILS_PAGE_URL = '/taxonDB/details/'
export const SAMPLESDB_DETAILS_REQUEST_URL = '/samples/details/'
export const SAMPLESDB_DETAILS_PAGE_URL = '/samples/details/'

export const BIBLIODB_DETAILS_REQUEST_URL = '/bibliography/details/'

export const TAXONDB_CHANGE_REQUEST_ID_PREFIX = 'taxondb-change-'
export const TAXONDB_CHANGE_REQUEST_URL = '/taxons/change/'
export const SAMPLESDB_CHANGE_REQUEST_ID_PREFIX = 'samplesdb-change-'
export const SAMPLESDB_CHANGE_REQUEST_URL = '/samples/change/'

export const BIBLIODB_CHANGE_REQUEST_ID_PREFIX = 'bibliodb-change-'
export const BIBLIODB_CHANGE_REQUEST_URL = '/bibliography/change/'

export const TAXONDB_CHANGE_GROUP_REQUEST_ID = 'taxondb-change-group'
export const TAXONDB_CHANGE_GROUP_REQUEST_URL = '/taxons/change_group/'

export const TAXONDB_SIMPLE_SEARCH_REQUEST_ID = 'taxondb-simple-search'
export const TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID = 'taxondb-simple-search-genus'
export const TAXONDB_SIMPLE_SEARCH_SPECIES_REQUEST_ID = 'taxondb-simple-search-species'
export const TAXONDB_SIMPLE_SEARCH_ICONS_REQUEST_ID = 'taxondb-simple-search-icons'

export const TAXONDB_SIMPLE2_SEARCH_REQUEST_ID = 'taxondb-simple2-search'
export const TAXONDB_SIMPLE2_SEARCH_GENUS_REQUEST_ID = 'taxondb-simple2-search-genus'
export const TAXONDB_SIMPLE2_SEARCH_SPECIES_REQUEST_ID = 'taxondb-simple2-search-species'
export const TAXONDB_SIMPLE2_SEARCH_ICONS_REQUEST_ID = 'taxondb-simple2-search-icons'

export const TAXONDB_DEFAULT_SEARCH_REQUEST_ID = 'taxondb-default-search'
export const TAXONDB_DEFAULT_SEARCH_GENUS_REQUEST_ID = 'taxondb-default-search-genus'
export const TAXONDB_DEFAULT_SEARCH_SPECIES_REQUEST_ID = 'taxondb-default-search-species'

export const TAXONDB_DYNAMIC_SEARCH_REQUEST_ID = 'taxondb-dynamic-search'
export const TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID = 'taxondb-dynamic-search-genus'
export const TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID = 'taxondb-dynamic-search-species'
export const TAXONDB_DYNAMIC_SEARCH_ICONS_REQUEST_ID = 'taxondb-dynamic-search-icons'

export const TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID = 'taxondb-dynamic2-search'
export const TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID = 'taxondb-dynamic2-search-genus'
export const TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID = 'taxondb-dynamic2-search-species'
export const TAXONDB_DYNAMIC2_SEARCH_ICONS_REQUEST_ID = 'taxondb-dynamic2-search-icons'

export const TAXONDB_SEARCH_REQUEST_ID = 'taxondb-search'
export const TAXONDB_SEARCH_GENUS_REQUEST_ID = 'taxondb-search-genus'
export const TAXONDB_SEARCH_SPECIES_REQUEST_ID = 'taxondb-search-species'
export const TAXONDB_SEARCH_ICONS_REQUEST_ID = 'taxondb-search-icons'

export const STATS_SEARCH_REQUEST_ID = 'stats-search'
export const STATS_DYNAMIC_SEARCH_REQUEST_ID = 'stats-dynamic-search'
export const STATS_DYNAMIC2_SEARCH_REQUEST_ID = 'stats-dynamic2-search'

export const BIBLIODB_SEARCH_REQUEST_ID = 'bibliodb-search'

export const SEARCH_BUTTONS_MODE_SAVE_FILTER = "mode-save-filter"

export const SAMPLESDB_SEARCH_REQUEST_ID = 'samplesdb-search'
export const SAMPLESDB_SEARCH_REQUEST_URL = '/samples/search/'
export const SAMPLESDB_DETAILS_REQUEST_ID_PREFIX = 'samplesdb-details-'

export const SAMPLESDB_SEARCH_MAP_DATA_REQUEST_ID = 'samplesdb-search-map-data'
export const SAMPLESDB_SEARCH_MAP_DATA_REQUEST_URL = '/samples/search_map_data/'

export const BIBLIODB_SEARCH_REQUEST_URL = '/bibliography/search/'
export const BIBLIODB_DETAILS_REQUEST_ID_PREFIX = 'bibliodb-details-'

export const TAXONDB_SEARCH_REQUEST_URL = '/taxons/search/'
export const TAXONDB_SEARCH_GENUS_REQUEST_URL = '/taxons/search_genus/'
export const TAXONDB_SEARCH_SPECIES_REQUEST_URL = '/taxons/search_species/'
export const TAXONDB_SEARCH_ICONS_REQUEST_URL = '/taxons/search_images/'

export const TAXONDB_SEARCH_MAP_DATA_REQUEST_ID = 'taxondb-search-map-data'
export const TAXONDB_SEARCH_MAP_DATA_REQUEST_URL = '/taxons/search_map_data/'

export const TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID = 'taxondb-search-with-aggregation'
export const TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL = '/taxons/search_with_aggregation/'

export const AGGREGATION_REPORT_REQUEST_ID_PREFIX = 'aggregation-report-'
export const SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX = 'samples-aggregation-report-'

export const TAXONDB_EXPORTFILE_REQUEST_PREFIX = 'taxondb-export-file-'
export const TAXONDB_EXPORTFILE_REQUEST_URL = '/taxons/export_file/'

export const BIBLIODB_EXPORTFILE_REQUEST_PREFIX = 'bibliodb-export-file-'

export const SAMPLESDB_EXPORTFILE_REQUEST_PREFIX = 'samplesdb-export-file-'
export const SAMPLESDB_EXPORTFILE_REQUEST_URL = '/samples/export_file/'

export const TAXONDB_EXPORTTEMPLATEFILE_REQUEST_PREFIX = 'taxondb-export-template-file-'
export const TAXONDB_EXPORTTEMPLATEFILE_REQUEST_URL = '/taxons/export_template_file/'

export const SAMPLESDB_EXPORTTEMPLATEFILE_REQUEST_PREFIX = 'samplesdb-export-template-file-'
export const SAMPLESDB_EXPORTTEMPLATEFILE_REQUEST_URL = '/samples/export_template_file/'

export const BIBLIODB_EXPORTTEMPLATEFILE_REQUEST_PREFIX = 'bibliodb-export-template-file-'
export const BIBLIODB_EXPORTTEMPLATEFILE_REQUEST_URL = '/bibliography/export_template_file/'

export const BIBLIODB_EXPORTFILE_REQUEST_URL = '/bibliography/export_file/'

export const TAXONDB_EXPORTFILE_WITH_AGGREGATION_REQUEST_URL = '/taxons/export_file_with_aggregation/'

export const DOWNLOADIMAGE_REQUEST_ID = 'taxondb-download-image'

export const GET_IMAGE_ATTRIBUTES_REQUEST_ID_PREFIX = 'get-image-attributes'
export const GET_IMAGE_ATTRIBUTES_REQUEST_URL = '/icons/get_attributes/'

export const TAXONDB_GET_TAXON_UNITS_REQUEST_ID = 'taxondb-get-taxon-units'
export const TAXONDB_GET_TAXON_UNITS_REQUEST_URL = '/taxon_units/'

export const PROTECTED_TAXONS_CHANGE_ID = 'change-protected-taxon'
export const PROTECTED_TAXONS_CHANGE_URL = '/protected_taxons/change/'
export const PROTECTED_TAXONS_CREATE_ID = 'create-protected-taxon'
export const PROTECTED_TAXONS_CREATE_URL = '/protected_taxons/create/'
export const PROTECTED_TAXONS_DELETE_ID = 'delete-protected-taxon'
export const PROTECTED_TAXONS_DELETE_URL = '/protected_taxons/delete/'
export const PROTECTED_TAXONS_EXPORTFILE_URL = '/protected_taxons/export_file/'
export const PROTECTED_TAXONS_GET_ID = 'get-protected-taxon'
export const PROTECTED_TAXONS_GET_URL = '/protected_taxons/get/'
export const PROTECTED_TAXONS_SEARCH_ID = 'search-protected-taxons'
export const PROTECTED_TAXONS_SEARCH_URL = '/protected_taxons/search/'
export const PROTECTION_SOURCES_GET_ALL_ID = 'get-all-protection-sources'
export const PROTECTION_SOURCES_GET_ALL_URL = '/protection_sources/get_all/'
export const PROTECTED_TAXONS_GET_SPECIMENS_ID = 'get-protected-taxon-specimens'
export const PROTECTED_TAXONS_GET_SPECIMENS_URL = '/protected_taxons/get_specimens/'

export const PROTECTED_AREAS_CHANGE_ID = 'change-protected-area'
export const PROTECTED_AREAS_CHANGE_URL = '/protected_areas/change/'
export const PROTECTED_AREAS_CREATE_ID = 'create-protected-area'
export const PROTECTED_AREAS_CREATE_URL = '/protected_areas/create/'
export const PROTECTED_AREAS_DELETE_ID = 'delete-protected-area'
export const PROTECTED_AREAS_DELETE_URL = '/protected_areas/delete/'
export const PROTECTED_AREAS_GET_ID = 'get-protected-area'
export const PROTECTED_AREAS_GET_URL = '/protected_areas/get/'
export const PROTECTED_AREAS_SEARCH_ID = 'search-protected-areas'
export const PROTECTED_AREAS_SEARCH_URL = '/protected_areas/search/'
export const PROTECTED_AREAS_GET_SPECIMENS_ID = 'get-protected-area-specimens'
export const PROTECTED_AREAS_GET_SPECIMENS_URL = '/protected_areas/get_specimens/'

export const PROJECTS_UPLOAD_OBSERVATION_IMAGE_ID = 'projects-upload-observation-image'
export const PROJECTS_UPLOAD_OBSERVATION_IMAGE_URL = 'https://rhus-16.man.poznan.pl:8000/api/project/upload_file/'
export const PROJECTS_DELETE_OBSERVATION_IMAGE_ID = 'projects-delete-observation-image'
export const PROJECTS_DELETE_OBSERVATION_IMAGE_URL = 'https://rhus-16.man.poznan.pl:8000/api/project/delete_file/'

export const TEAM_COPY_OBSERVATION_REQUEST_ID_PREFIX = "team-copy-observation-"
export const TEAM_COPY_OBSERVATION_REQUEST_URL = "https://rhus-16.man.poznan.pl:8000/api/project/copy_observation/"

export const PROJECTS_ADD_OBSERVATION_ID = 'projects-add-observation'
export const PROJECTS_ADD_OBSERVATION_URL = '/project/add_observation/'
export const PROJECTS_CHANGE_OBSERVATION_NAME_ID = 'projects-change-observation-name'
export const PROJECTS_CHANGE_OBSERVATION_NAME_URL = '/project/change_observation_name/'
export const PROJECTS_CHANGE_OBSERVATION_ID_PREFIX = 'projects-change-observation-'
export const PROJECTS_CHANGE_OBSERVATION_URL = '/project/edit_observation/'
export const PROJECTS_CHANGE_OBSERVATION_PROJECT_ID = 'projects-change-observation-project'
export const PROJECTS_CHANGE_OBSERVATION_PROJECT_URL = '/project/move_observation/'
export const PROJECTS_REMOVE_OBSERVATION_ID = 'projects-remove-observation'
export const PROJECTS_REMOVE_OBSERVATION_URL = '/project/remove_observation/'
export const PROJECTS_CREATE_PROJECT_ID = 'projects-create-project'
export const PROJECTS_CREATE_PROJECT_URL = '/project/create/'
export const PROJECTS_CHANGE_PROJECT_NAME_ID = 'projects-change-project-name'
export const PROJECTS_CHANGE_PROJECT_NAME_URL = '/project/change_name/'
export const PROJECTS_DELETE_PROJECT_ID = 'projects-delete-project'
export const PROJECTS_DELETE_PROJECT_URL = '/project/delete/'
export const PROJECTS_GET_ALL_ID = 'projects-get-all'
export const PROJECTS_GET_ALL_1000_ID = 'projects-get-all-1000'
export const PROJECTS_GET_ALL_URL = '/project/get_all/'
export const PROJECTS_GET_OBSERVATION_ID = 'projects-get-observation'
export const PROJECTS_GET_OBSERVATION_URL = '/project/get_observation/'
export const PROJECTS_GET_OBSERVATIONS_ID = 'projects-get-observations'
export const PROJECTS_GET_OBSERVATIONS_URL = '/project/get_observations/'
export const PROJECTS_GET_OBSERVATIONS_DETAILS_ID = 'projects-get-observations-details'
export const PROJECTS_GET_OBSERVATIONS_DETAILS_URL = '/project/get_observations_details/'
export const PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID = 'projects-search-observations-request'
export const PROJECTS_SEARCH_OBSERVATIONS_URL = '/project/search_observations/'
//export const PROJECTS_GET_PREFIX = 'projects-get-prefix-'

export const API_FILTER_GET_ALL_ID = 'get-all-filters'
export const API_FILTER_GET_ALL_URL = '/filter/all_filters/'
export const API_FILTER_GET_ITEMS_ID_PREFIX = 'get-filter-items-'
export const API_FILTER_GET_ITEMS_URL = '/filter/items/'
export const API_FILTER_SET_ID = 'set-filter'
export const API_FILTER_SET_URL = '/filter/set/'
export const API_FILTER_CHANGE_NAME_ID = 'change-filter-name'
export const API_FILTER_CHANGE_NAME_URL = '/filter/change_name/'
export const API_FILTER_DELETE_ID = 'delete-filter'
export const API_FILTER_DELETE_URL = '/filter/del/'
export const API_ALBUM_GET_ALL_ID = 'get-all-albums'
export const API_ALBUM_GET_ALL_URL = '/album/albums/'
export const API_ALBUM_GET_ITEMS_ID_PREFIX = 'get-album-items-'
export const API_ALBUM_GET_ITEMS_URL = '/album/items/'
export const API_ALBUM_ADD_ITEM_ID = 'add-item-to-album'
export const API_ALBUM_ADD_ITEM_URL = '/album/add_item/'
export const API_ALBUM_CHANGE_NAME_ID = 'change-album-name'
export const API_ALBUM_CHANGE_NAME_URL = '/album/change_name/'
export const API_ALBUM_EDIT_ITEM_ID = 'edit-album-item'
export const API_ALBUM_EDIT_ITEM_URL = '/album/edit_item/'
export const API_ALBUM_DELETE_ITEM_ID = 'delete-item-from-album'
export const API_ALBUM_DELETE_ITEM_URL = '/album/del_item/'
export const API_ALBUM_PRESENTATION_URL = '/album/presentation/'
export const API_ALBUM_PRESENTATION_ITEMS_URL = '/album/presentation_items/'
export const API_ALBUM_PRESENTATION_ID_PREFIX = 'album-presentation-'
export const API_ALBUM_DOWNLOAD_PDF_ID = 'album-to-pdf-download'
export const API_ALBUM_DOWNLOAD_PDF_URL = '/album/gen_pdf/'
export const API_ALBUM_SEARCH_ITEMS_REQUEST_ID = 'album-search-items-request'
export const API_ALBUM_SEARCH_ITEMS_URL = '/album/search_items/'
export const API_ALBUM_MOVE_ITEM_ID = 'album-move-item'
export const API_ALBUM_MOVE_ITEM_URL = '/album/move_item/'

export const API_TEAM_ADD_TEAM_ID = 'add-team'
export const API_TEAM_ADD_TEAM_URL = '/team/add_team/'
export const API_TEAM_ADD_USER_ID = 'add-user-to-team'
export const API_TEAM_ADD_USER_URL = '/team/add_user/'
export const API_TEAM_DEL_TEAM_ID = 'del-team'
export const API_TEAM_DEL_TEAM_URL = '/team/del_team/'
export const API_TEAM_DEL_USER_ID = 'del-user-from-team'
export const API_TEAM_DEL_USER_URL = '/team/del_user/'
export const API_TEAM_GET_TEAMS_ID = 'get-teams'
export const API_TEAM_GET_TEAMS_URL = '/team/get_teams/'
export const API_TEAM_GET_USERS_ID = 'get-team-users'
export const API_TEAM_GET_USERS_URL = '/team/get_users/'
export const API_TEAM_SET_ADMIN_ID = 'set-team-admin'
export const API_TEAM_SET_ADMIN_URL = '/team/set_admin/'
export const API_TEAM_UNSET_ADMIN_ID = 'unset-team-admin'
export const API_TEAM_UNSET_ADMIN_URL = '/team/unset_admin/'
export const API_TEAM_GET_URL_ID = 'get-team-url'
export const API_TEAM_GET_URL_URL = '/team/get_url/'
export const API_TEAM_REMOVE_PIN_ID = 'remove-team-pin'
export const API_TEAM_REMOVE_PIN_URL = '/team/remove_pin/'
export const API_TEAM_ADD_ME_ID = 'team-add-me'
export const API_TEAM_ADD_ME_URL = '/team/add_me/'
export const API_TEAM_ACCEPT_USER_ID = 'accept-user-to-team'
export const API_TEAM_ACCEPT_USER_URL = '/team/accept_user/'
export const API_TEAM_CREATE_INVITATION_ID = 'team-create-invitation'
export const API_TEAM_CREATE_INVITATION_URL = '/team/create_invitation/'
export const API_TEAM_SEND_INVITATION_ID = 'team-send-invitation'
export const API_TEAM_SEND_INVITATION_URL = '/team/send_invitation/'
export const API_TEAM_GET_INVITATION_DETAILS_ID = 'team-get-invitation-details'
export const API_TEAM_GET_INVITATION_DETAILS_URL = '/team/invitation_details/'
export const API_TEAM_ACCEPT_INVITATION_ID = 'team-accept-invitation'
export const API_TEAM_ACCEPT_INVITATION_URL = '/team/accept_invitation/'
export const API_TEAM_REJECT_INVITATION_ID = 'team-reject-invitation'
export const API_TEAM_REJECT_INVITATION_URL = '/team/reject_invitation/'
export const API_TEAM_GET_APPLICATION_DETAILS_ID = 'team-get-application-details'
export const API_TEAM_GET_APPLICATION_DETAILS_URL = '/team/info/'
export const API_TEAM_ADD_ME_REQUEST_ID = 'team-add-me-request-id'
export const API_TEAM_ADD_ME_REQUEST_URL = '/team/add_me/'

export const API_PROJECT_GET_USER_SPACE_ID = 'project-get-user-space'
export const API_PROJECT_GET_USER_SPACE_URL = '/project/get_user_space/'

export const API_GET_VISITS_PER_DAY_ID = 'api-visits-per-day'
export const API_GET_VISITS_PER_MONTH_ID = 'api-visits-per-month'
export const API_GET_VISITS_PER_DAY_URL = '/reports/visits_per_day/'
export const API_GET_VISITS_PER_MONTH_URL = '/reports/visits_per_month/'
export const API_GET_CUMULATIVE_VISITS_PER_DAY_ID = 'cumulative-api-visits-per-day'
export const API_GET_CUMULATIVE_VISITS_PER_MONTH_ID = 'api-cumulative-visits-per-month'
export const API_GET_CUMULATIVE_VISITS_PER_DAY_URL = '/reports/cumulative_visits_per_day/'
export const API_GET_CUMULATIVE_VISITS_PER_MONTH_URL = '/reports/cumulative_visits_per_month/'

export const API_GET_DOWNLOADS_STATS_PER_DAY_ID = 'api-stats-downloads-per-day'
export const API_GET_DOWNLOADS_STATS_PER_MONTH_ID = 'api-stats-downloads-per-month'
export const API_GET_DOWNLOADS_STATS_PER_DAY_URL = '/reports/details_stats/per_day/'
export const API_GET_DOWNLOADS_STATS_PER_MONTH_URL = '/reports/details_stats/per_month/'

export const API_GET_COLLECTIONS_STATS_URL = '/reports/collection_stats/'
export const API_GET_COLLECTIONS_STATS_ID = 'api-reports-get-collections-stats'
export const API_GET_COLLECTIONS_WITHOUT_TYPES_URL = '/reports/collections_without_type/'
export const API_GET_COLLECTIONS_WITHOUT_TYPES_ID = 'api-reports-collections-without-type'
export const API_EXPORT_COLLECTIONS_REPORT_ID = 'api-export-collections-id'
export const API_EXPORT_COLLECTIONS_REPORT_URL = '/reports/export_collection_stats/'

export const TAXONDB_GET_COLLECTION_SUBTYPES_URL = '/collection_subtypes/'
export const TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX = 'taxondb-collection-subtypes-'
export const TAXONDB_GET_COLLECTIONS_URL = '/collections/'
export const TAXONDB_GET_COLLECTIONS_PREFIX = 'taxondb-collections-'
export const COLLECTION_TYPES_REQUEST_ID = 'get-collection-types'
export const COLLECTION_TYPES_REQUEST_URL = '/collection_types/'

export const COLLECTION_TYPES_CREATE_REQUEST_ID = 'collection-edit-type'
export const COLLECTION_TYPES_CREATE_REQUEST_URL = '/collection_types/create/'
export const COLLECTION_TYPES_EDIT_REQUEST_ID = 'collection-edit-type'
export const COLLECTION_TYPES_EDIT_REQUEST_URL = '/collection_types/edit/'
export const COLLECTION_SUBTYPES_CREATE_REQUEST_ID = 'collection-create-subtype'
export const COLLECTION_SUBTYPES_CREATE_REQUEST_URL = '/collection_subtypes/create/'
export const COLLECTION_SUBTYPES_EDIT_REQUEST_ID = 'collection-edit-subtype'
export const COLLECTION_SUBTYPES_EDIT_REQUEST_URL = '/collection_subtypes/edit/'
export const COLLECTION_EDIT_REQUEST_ID = 'collection-edit'
export const COLLECTION_EDIT_REQUEST_URL = '/collections/edit/'

export const COLLECTION_GENUS_RESULTS_REQUEST_PREFIX = 'get-collection-genus-results-'
export const COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX = 'get-collection-species-results-'
export const COLLECTION_ICONS_RESULTS_REQUEST_PREFIX = 'get-collection-icons-results-'
export const COLLECTION_RESULTS_REQUEST_PREFIX = 'get-collection-results-'
export const COLLECTION_RESULTS_TAB_REQUEST_PREFIX = 'get-collection-results-tab-'

export const API_GET_SIZE_STATS_URL = '/reports/size_stats/'
export const API_GET_SIZE_STATS_ID = 'api-reports-size-stats-id'

export const ICONDB_SEARCH_REQUEST_ID = 'icondb-search'
export const ICONDB_SEARCH_REQUEST_URL = '/icons/search/'

export const ICONDB_SEARCH_MAP_DATA_REQUEST_ID = 'icondb-search-map-data'
export const ICONDB_SEARCH_MAP_DATA_REQUEST_URL = '/icons/search_map_data/'

export const ICONDB_DETAILS_REQUEST_ID_PREFIX = 'icondb-details-'
export const ICONDB_DETAILS_REQUEST_URL = '/icons/details/'
export const ICONDB_DETAILS_PAGE_URL = '/iconDB/details/'
export const ICONDB_CHANGE_REQUEST_ID = 'icondb-change'
export const ICONDB_CHANGE_REQUEST_URL = '/icons/change/'

export const TAXONDB_SUGGEST_REQUEST_PREFIX = '/taxons/suggest/'
export const BIBLIODB_SUGGEST_REQUEST_PREFIX = '/bibliography/suggest/'
export const SAMPLESDB_SUGGEST_REQUEST_PREFIX = '/samples/suggest/'
export const ICONDB_SUGGEST_REQUEST_PREFIX = '/icons/suggest/'

export const API_GET_USER_DETAILS_ID = 'api-get-user-details-'
export const API_GET_USER_DETAILS_URL = '/user/'
export const API_GET_USERS_BY_ROLE_ID = 'api-get-users-by-role-'
export const API_GET_LEADERS_URL = '/role/get_leaders/'
export const API_GET_LEADERS_ID = 'api-get-leaders-by-role-'
export const API_GET_USERS_BY_ROLE_URL = '/role/get_users/'
export const API_ROLE_REMOVE_USER_ID = 'role-remove-user'
export const API_ROLE_REMOVE_USER_URL = '/role/remove_user/'
export const API_ROLE_ADD_USER_ID = 'role-add-user-'
export const API_ROLE_ADD_USER_URL = '/role/add_user/'

export const API_TASK_REQUEST_PREFIX = 'api-task-'
export const API_TASK_REQUEST_URL = '/task/'

export const API_GET_EDITORS_NAMES_ID = 'users'
export const API_GET_EDITORS_NAMES_URL = 'users/'
export const API_GET_EDITIONS_LIST_ID = 'search'
export const API_GET_EDITIONS_LIST_URL = 'search/'
export const API_TASK_DETAILS_ID = 'result-id'
export const API_TASK_DETAILS_URL = 'result/'

export const ANC_TAXON_SOURCE_FILES_ID = 'anc-taxon-source-files'
export const ANC_TAXON_SOURCE_FILES_URL = '/admin/sources/search/'
export const ANC_TAXON_FILE_REPORTS_ID = 'anc-taxon-file-reports'
export const ANC_TAXON_FILE_REPORTS_URL = '/admin/file_reports/search/'

export const TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME = 'taxon-results-tab-key'
export const SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME = 'samples-results-tab-key'
export const SAMPLESDB_RESULTS_ACTIVE_TAB_FIELD_NAME = 'samplesdb-results-tab-key'
export const TAXON_SIMPLE_RESULTS_ACTIVE_TAB_FIELD_NAME = 'taxon-simple-results-tab-key'
export const TAXON_SIMPLE2_RESULTS_ACTIVE_TAB_FIELD_NAME = 'taxon-simple2-results-tab-key'
export const TAXON_DEFAULT_RESULTS_ACTIVE_TAB_FIELD_NAME = 'taxon-default-results-tab-key'
export const TAXON_DYNAMIC_RESULTS_ACTIVE_TAB_FIELD_NAME = 'taxon-dynamic-results-tab-key'
export const TAXON_DYNAMIC2_RESULTS_ACTIVE_TAB_FIELD_NAME = 'taxon-dynamic2-results-tab-key'

export const ICON_RESULTS_ACTIVE_RECORD = 'icon-active-record'
export const TAXONDB_RESULTS_ACTIVE_RECORD = 'taxondb-active-record'
export const TAXONDB_RESULTS_ACTIVE_ICON_RECORD = 'taxondb-active-icon-record'

export const TAXON_SEARCH_IS_DYNAMIC = 'taxon-search-is-dynamic'
export const STATS_SEARCH_IS_DYNAMIC = 'stats-search-is-dynamic'

export const TAXONDB_COUNT_REQUEST_ID = 'taxondb-count'
export const TAXONDB_COUNT_REQUEST_URL = '/taxons/count/'

export const COMMON_DICTIONARIES = 'common-dictionaries'
export const DICTIONARY_COLLECTION_TYPES = 'dictionary-collection-types'

export const DLIBRA_IIIF_URL = 'https://rhus-103.man.poznan.pl/iiif/image/'
export const DLIBRA_IIIF2_URL = 'https://rhus-16.man.poznan.pl/iiif/image/'
export const DLIBRA_IIIF_THUMBNAIL_URL = 'https://rhus-103.man.poznan.pl/image/edition/thumbnail:default/'
export const DLIBRA_IMAGE_JPG_MAX_WIDTH = 640 // in pixels
export const DLIBRA_CONTENT_URL = 'https://rhus-16.man.poznan.pl/Content/'

export const API_URL_POSTFIX = '/api'
export const ANC_URL_POSTFIX = '/anc'
export const DLIBRA_URL_POSTFIX = '/dlibra'

export const GOOGLE_RECAPTCHA_SITE_KEY = '6LdmHzYgAAAAAA25qETlrosjNno-L56QQAEn3UAY'

export const MAXIMUM_EXCEL_ROWS = 10000

export const SUGGEST_DEBOUNCE_TIME_DELAY = 150 // ms

export const API_BASE_URL = (window.location.hostname === 'amunatcoll.pl') ? 'https://api.amunatcoll.pl' : (window.location.hostname === 'rhus-76.man.poznan.pl') ? 'https://rhus-76.man.poznan.pl:8000' : (window.location.hostname === 'sophora-43.man.poznan.pl') ? 'https://sophora-43.man.poznan.pl:8000' : 'https://localhost:8000'
export const PORTAL_BASE_URL = (window.location.hostname === 'amunatcoll.pl') ? 'https://amunatcoll.pl' : (window.location.hostname === 'rhus-76.man.poznan.pl') ? 'https://rhus-76.man.poznan.pl' : (window.location.hostname === 'sophora-43.man.poznan.pl') ? 'https://sophora-43.man.poznan.pl:3000' : 'https://localhost:3000'

//export const FACEBOOK_LOGIN_APP_ID = (window.location.hostname === 'rhus-76.man.poznan.pl') ? '497068710905120' : (window.location.hostname === 'sophora-43.man.poznan.pl') ? '2381251455535730' : '364354234513464'
export const FACEBOOK_LOGIN_APP_ID = '559838417986905'

export const GOOGLE_LOGIN_CLIENT_ID = (window.location.hostname === 'amunatcoll.pl') ? '323049326633-vfh9e4nbkr63v8fdik72ius3vs1den4m.apps.googleusercontent.com' : (window.location.hostname === 'rhus-76.man.poznan.pl') ? '323049326633-uqb8r0chk6n14pd5rnasbdi1pildvpq7.apps.googleusercontent.com' : (window.location.hostname === 'sophora-43.man.poznan.pl') ? '323049326633-id24qkvrfoh495v4a5sfi9rhf39bflop.apps.googleusercontent.com' : '323049326633-9kscp97iophti6c5ktkf799k2pmn4tvr.apps.googleusercontent.com'
export const GOOGLE_OAUTH2_REDIRECT_URL = "/google_callback/"

export const APPLE_LOGIN_CLIENT_ID = "pl.amunatcoll.web"
export const APPLE_OAUTH2_REDIRECT_URL = "/apple_callback/"

export const GOOGLE_ANALYTICS_UID = 'UA-185628034-1'

export const USER_GROUPS_COORDINATOR_CONST = 'Koordynator'
export const USER_GROUPS_EDITOR_CONST = 'Edytor'
export const USER_GROUPS_LEADER_CONST = 'Lider'
export const USER_GROUPS_SUPERLEADER_CONST = 'Superlider'
export const USER_GROUPS_CONFIRMED_CONST = 'Potwierdzony'
export const USER_GROUPS_TRUSTED_CONST = 'Zaufany'
export const USER_GROUPS_VALIDATOR_CONST = 'Walidator'

export const USER_GUI_SCIENTIST_CONST = "scientifist"
// export const USER_GUI_DEFAULT_CONST = "default"
// export const USER_GUI_EDUCATION_CONST = "education"

export const GENERAL_DEFAULT_REQUEST_TIMEOUT = 40000 // ms
export const GENERAL_EXTENDED_REQUEST_TIMEOUT = 40000 // ms
export const GENERAL_REPORT_REQUEST_TIMEOUT = 60000 // ms
export const MAP_DATA_REQUEST_TIMEOUT = 60000 // ms
export const CARTODIAGRAM_REQUEST_TIMEOUT = 240000 // ms
export const MAP_DATA_UNCLASTERED_REQUEST_TIMEOUT = 12000 // ms
export const DLIBRA_TIF_IMAGE_REQUEST_TIMEOUT = 120000 // ms
export const GEOJSON_EXTENDED_REQUEST_TIMEOUT = 900000 // ms

export const MAP_MAX_ZOOM_LEVEL = 18
export const MAP_CLUSTER_MARKER_COUNT_LIMIT = 100
export const MAP_UNCLASTERED_MAXIMUM_MARKERS = 5000

export const RECORD_DETAILS_LOCATION_STATE_DATE_POPUP = '22.10.2019'

export const GENUS_SPECIES_ITALIC_EXCLUDES = [ 'sp.' ]

export const MAP_LAYER_CLASTERED_TAXONS_CONST = 'ancClasteredTaxons'
export const MAP_LAYER_UNCLASTERED_TAXONS_CONST_REF = 'unclasteredAncTaxonsRef'
export const MAP_LAYER_TAXONS_WITH_LEGEND_CONST = 'ancTaxonsWithLegend'
export const MAP_LAYER_HISTORICAL_ANALYSIS_CONST = 'historicalAnalysisRef'
export const MAP_LAYER_ANIMATION_CONST = 'animationLayerRef'
export const MAP_LAYER_CARTOGRAM_CONST = 'cartogramLayerRef'
export const MAP_LAYER_OBSERVATIONS_CONST = 'OBSERVATIONS'
export const USER_DRAWINGS_ALL_LAYERS_CONST = 'USER_DRAWINGS_ALL_LAYERS'
export const USER_DRAWINGS_POINTS_CONST = 'USER_DRAWINGS_POINTS'
export const USER_DRAWINGS_LINES_CONST = 'USER_DRAWINGS_LINES'
export const USER_DRAWINGS_POLYGONS_CONST = 'USER_DRAWINGS_POLYGONS'
export const ICON_STYLE_MENU = "ICON_STYLE_MENU"
export const PREDEFINED_WMS = "PREDEFINED_WMS"
export const LAYERS_CONTROL_PANEL = 'LAYERS_CONTROL_PANEL'

export const STATISTICS_GENUS_SEARCH_REQUEST_PREFIX = 'statistics-genus-search-'

export const MEMBER_KIND_ADMINISTRATOR = 'administrator'
export const MEMBER_KIND_NORMAL_USER = 'uczestnik'
export const MEMBER_KIND_APPLICANT = 'aplikant'
export const MEMBER_KIND_REJECTED = 'odrzucony'
export const MEMBER_KIND_MANAGER = 'kierownik'

export const TAXON_FIELDS_ALLOWED_IN_GROUP_CHANGE = [
  GENUS_ATTRIBUTE_NAME,
  SPECIES_ATTRIBUTE_NAME,
  "jednostkanadrzedna",
  "autorzbioru",
  "datazebraniaokazuproby",
  "autorgatunku",
  "rangajednostkinadrzednej",
  "zrodlo",
  "panstwo",
  "georeferencjakomentarze",
  "gmina",
  "koditypobszarunatura2000",
  "kontynent",
  "nazwaobszarunatura2000",
  "numerzbioruokreslonegoautora",
  "obszarchronionegokrajobrazu",
  "obszarchroniony",
  "parkkrajobrazowy",
  "parknarodowy",
  "polozeniewpodzialebiogeograficznymeuropy",
  "polozeniewpodzialefizjograficznym",
  "polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne",
  "powiat",
  "rezerwatprzyrody",
  "rodzajityprezerwatu",
  "siedlisko",
  "uzytekekologiczny",
  "wojewodztwo",
  "zespolprzyrodniczokrajobrazowy",
  "instytucja",
  "opisuwagi",
  "pochodzenieokazu",
  "bibliografia",
]

export const mapAttributionString = '<a href="https://leafletjs.com/">Leaflet</a> | <a href="http://anc.amu.edu.pl/">AMUNATCOLL</a> | <a href="https://www.openstreetmap.org/">OpenStreetMap</a> | <a href="https://hub.arcgis.com/datasets/esri::world-utm-grid"> ESRI World UTM Grid</a>'
export const reactMapAttributionString = '<a href="http://anc.amu.edu.pl/">AMUNATCOLL</a> | <a href="https://www.openstreetmap.org/">OpenStreetMap</a> | <a href="https://hub.arcgis.com/datasets/esri::world-utm-grid"> ESRI World UTM Grid</a>'

export const MAPBOX_HEATMAP_ACCESS_TOKEN = 'pk.eyJ1Ijoic2V1bDg4IiwiYSI6ImNraWVkZm1xMzFyeDYyeG81NW1yMDUwa24ifQ.l4IFtU2PswIV_KFUgnp0og'
export const MAPBOX_HEATMAP_STYLE_UNIQUE_ID = "mapbox://styles/seul88/ckkfala4o138517ru6z4ijjcx"

export const FONT_SIZE_SCALE_1 = "16px"
export const FONT_SIZE_SCALE_2 = "18px"
export const FONT_SIZE_SCALE_3 = "21px"

export const ANC_ADMIN_VALIDATE_REQUEST_ID = "anc-admin-validate-request-id"
export const ANC_ADMIN_VALIDATE_REQUEST_URL = "/admin/validate_excel/"
export const ANC_ADMIN_CONVERT_REQUEST_ID = "anc-admin-convert-request-id"
export const ANC_ADMIN_CONVERT_REQUEST_URL = "/admin/convert_excel/"
export const ANC_ADMIN_IMPORT_REQUEST_ID = "anc-admin-import-request-id"
export const ANC_ADMIN_IMPORT_REQUEST_URL = "/admin/import_excel/"

export const API_EXCEL_TASK_SEARCH_REQUEST_ID = "api-excel-task-search-id"
export const API_EXCEL_TASK_SEARCH_REQUEST_URL = "/excel_task/search/"
export const API_EXCEL_TASK_DETAILS_REQUEST_ID_PREFIX = "api-excel-task-details-id-"
export const API_EXCEL_TASK_DETAILS_REQUEST_URL = "/excel_task/result/"
export const API_EXCEL_TASK_DELETE_REQUEST_ID = "api-excel-task-delete-id"
export const API_EXCEL_TASK_DELETE_REQUEST_URL = "/excel_task/delete/"
export const API_EXCEL_TASK_DOWNLOAD_REQUEST_ID = "api-excel-task-download-id"
export const API_EXCEL_TASK_DOWNLOAD_REQUEST_URL = "/excel_task/file/"

export const ANC_TAXON_HISTORY_REQUEST_ID = "anc-admin-get-taxon-edit-history"
export const ANC_TAXON_HISTORY_REQUEST_URL = "/admin/get_taxon_edit_history/"
export const ANC_TAXON_HISTORY_DETAILS_REQUEST_ID = "anc-admin-get-taxon-edit-details-"
export const ANC_TAXON_HISTORY_DETAILS_REQUEST_URL = "/admin/get_taxon_edit_details/"
export const ANC_TAXON_EDIT_USERS_REQUEST_ID = "anc-admin-taxon-edit-users"
export const ANC_TAXON_EDIT_USERS_REQUEST_URL = "/admin/get_taxon_edit_users/"

export const ANC_SAMPLE_HISTORY_REQUEST_ID = "anc-admin-get-sample-edit-history"
export const ANC_SAMPLE_HISTORY_REQUEST_URL = "/admin/get_sample_edit_history/"
export const ANC_SAMPLE_HISTORY_DETAILS_REQUEST_ID = "anc-admin-get-sample-edit-details-"
export const ANC_SAMPLE_HISTORY_DETAILS_REQUEST_URL = "/admin/get_sample_edit_details/"
export const ANC_SAMPLE_EDIT_USERS_REQUEST_ID = "anc-admin-sample-edit-users"
export const ANC_SAMPLE_EDIT_USERS_REQUEST_URL = "/admin/get_sample_edit_users/"

export const ANC_ICON_HISTORY_REQUEST_ID = "anc-admin-get-icon-edit-history"
export const ANC_ICON_HISTORY_REQUEST_URL = "/admin/get_icon_edit_history/"
export const ANC_ICON_HISTORY_DETAILS_REQUEST_ID = "anc-admin-get-icon-edit-details-"
export const ANC_ICON_HISTORY_DETAILS_REQUEST_URL = "/admin/get_icon_edit_details/"
export const ANC_ICON_EDIT_USERS_REQUEST_ID = "anc-admin-icon-edit-users"
export const ANC_ICON_EDIT_USERS_REQUEST_URL = "/admin/get_icon_edit_users/"

export const ANC_BIBLIO_HISTORY_REQUEST_ID = "anc-admin-get-biblio-edit-history"
export const ANC_BIBLIO_HISTORY_REQUEST_URL = "/admin/get_biblio_edit_history/"
export const ANC_BIBLIO_HISTORY_DETAILS_REQUEST_ID = "anc-admin-get-biblio-edit-details-"
export const ANC_BIBLIO_HISTORY_DETAILS_REQUEST_URL = "/admin/get_biblio_edit_details/"
export const ANC_BIBLIO_EDIT_USERS_REQUEST_ID = "anc-admin-biblio-edit-users"
export const ANC_BIBLIO_EDIT_USERS_REQUEST_URL = "/admin/get_biblio_edit_users/"

export const EDITION_TYPE_CREATE = "create"
export const EDITION_TYPE_UPDATE = "update"
export const EDITION_TYPE_MERGE = "merge"
export const EDITION_TYPE_SINGLE_EDIT = "single_edit"
export const EDITION_TYPE_GROUP_EDIT = "group_edit"

export const EDITOR_TOOLS_SHEET_TYPES = [ "brak", "icon-takson", "icon-takson-obserwacje", "icon-takson-notatki", "icon-varia", "icon-mapy", "icon-bibliografie" ]
export const EDITOR_TOOLS_CONFIGURATIONS = [ "konwerter dat formatów i kolejności", "BW", "POZG" ]
export const EDITOR_TOOLS_EDITION_TYPES = [ "import", "update", "merge" ]

export const BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX = "biblio-specimens-request-"
export const BIBLIODB_SPECIMENS_REQUEST_URL = "/bibliography/get_taxons/"
export const BIBLIODB_EXPORTFILE_SPECIMENS_REQUEST_URL = "/bibliography/export_taxons_file/"
export const BIBLIODB_SAMPLES_REQUEST_ID_PREFIX = "biblio-samples-request-"
export const BIBLIODB_SAMPLES_REQUEST_URL = "/bibliography/get_samples/"
export const BIBLIODB_EXPORTFILE_SAMPLES_REQUEST_URL = "/bibliography/export_samples_file/"

export const SOCIAL_MENU_VERSION_ID = "soc-menu-version-id"
export const TAXON_MAP_CARTODIAGRAM_COLOR_ARRAY = [ "blue", "brown", "green", "orange", "red", "violet", "yellow", "teal", "#d0fa75", "gray" ]
export const TAXON_MAP_GROUPING_SHAPE_ARRAY = [
  "square_solid",
  "square_regular",
  "star_solid",
  "star_regular",
  "times_solid",
  "thumbtack_solid",
  "map_marker",
  "map_pin",
  "circle_solid",
  "circle_regular",
  "cube_solid",
  "bookmark_solid",
  "asterisk_solid",
]
export const TAXON_MAP_GROUPING_COLOR_ARRAY = [ 
  "000000", //black
  0x159A35, //green
  0x13D1A1, //teal
  0x1A14D4,  //blue
  0x6F27B6, //indigo
  0x56331E, //brown
  0xFE7D2E, //gray
  0xFFFFFF, //white
  0xE51313, //red
  0xFFFF00 //yellow
]

export const COLORS_PAIRS = {
  "#000000": "000000",
  "#0000ff": 0x0000ff,
  "#a52a2a": 0xa52a2a, 
  "#008000": 0x008000, 
  "#ffa500": 0xffa500, 
  "#ff0000": 0xff0000,
  "#ee82ee": 0xee82ee, 
  "#ffff00": 0xffff00, 
  "#008080": 0x008080, 
  "#d0fa75": 0xd0fa75, 
  "#808080": 0x808080,
  "#ffffff": 0xffffff
}

export const MAP_COLORPICKER_LIST = [ "#000000", "#0000ff", "#a52a2a", "#008000", "#ffa500", "#ff0000", "#ee82ee", "#ffff00", "#008080", "#d0fa75", "#808080", "#ffffff" ]

export const TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_COLOR_LEGEND = 10
export const TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_SHAPE_LEGEND = 13
export const TAXON_MAP_GROUPING_COLOR_FIELD_CONST = "COLOR_FIELD"
export const TAXON_MAP_GROUPING_SHAPE_FIELD_CONST = "SHAPE_FIELD"

//ROUTING PATHS
//MONOLINGUAL

export const HISTORICAL_PAGE_URL_EN = "/historical_map"

export const HEATMAP_PAGE_URL_EN = "/heatmap"

export const MAP_MENU_PAGE_URL_EN = "/map_menu"

export const RESET_PASSWORD_PAGE_URL_EN = "/reset_password"

export const REGISTRATION_CONFIRM_PAGE_URL_EN = "/confirm"

export const PASSWORD_REMINDER_PAGE_URL_EN = "/reminder"

//MULTILINGUAL
export const ARRANGEMENT_PAGE_URL_EN = "/arrangement"
export const ARRANGEMENT_PAGE_URL_PL = "/mapa-punktowa"

export const CHOROPLETH_PAGE_URL_EN ="/choropleth"
export const CHOROPLETH_PAGE_URL_PL ="/kartogram"

export const CLUSTERING_PAGE_URL_EN = "/clustering"
export const CLUSTERING_PAGE_URL_PL = "/mapa-klastrow"

export const AREA_MAP_PAGE_URL_EN ="/area-map"
export const AREA_MAP_PAGE_URL_PL ="/mapa-powierzchniowa"

export const CARTOGRAM_PAGE_URL_EN = "/cartogram"
export const CARTOGRAM_PAGE_URL_PL = "/kartogram"

export const CARTODIAGRAM_PAGE_URL_EN ="/cartodiagram"
export const CARTODIAGRAM_PAGE_URL_PL ="/kartodiagram"

export const TIMELAPSE_PAGE_URL_EN ="/timelapse"
export const TIMELAPSE_PAGE_URL_PL ="/zmiennosc-czasoprzestrzenna"

export const GROUPING_PAGE_URL_EN = "/grouping-taxons" 
export const GROUPING_PAGE_URL_PL = "/grupowanie" 

export const EXCEL_TOOLS_PAGE_URL_EN = "/excel-files-tools"
export const EXCEL_TOOLS_PAGE_URL_PL = "/narzedzia-plikow-excel"

export const TASK_HISTORY_PAGE_URL_EN = "/task-history"
export const TASK_HISTORY_PAGE_URL_PL = "/historia-zadan"

export const DB_HISTORY_PAGE_URL_EN = "/database-changes-history"
export const DB_HISTORY_PAGE_URL_PL = "/historia-zmian-w-bazie"

export const STATS_PAGE_URL_EN = "/statistical-reports"
export const STATS_PAGE_URL_PL = "/raporty-statystyczne"

export const FILE_REPORTS_PAGE_URL_EN = "/files-reports"
export const FILE_REPORTS_PAGE_URL_PL = "/raport-plikow"

export const TAXON_SOURCES_PAGE_URL_EN = "/taxon-source-files"
export const TAXON_SOURCES_PAGE_URL_PL = "/zrodla-plikow"

export const OUR_USERS_PAGE_URL_EN = "/our-users"
export const OUR_USERS_PAGE_URL_PL = "/nasi-uzytkownicy"

export const MOBILE_INFO_PAGE_URL_EN = "/mobile-application"
export const MOBILE_INFO_PAGE_URL_PL = "/aplikacja-mobilna"

export const MISSION_PAGE_URL_EN = "/mission"
export const MISSION_PAGE_URL_PL = "/misja"

export const INFORMATIONS_PAGE_URL_EN = "/portal-info"
export const INFORMATIONS_PAGE_URL_PL = "/informacje-o-portalu"

export const GIS_INFO_PAGE_URL_EN = "/biogis-and-data-visualisation"
export const GIS_INFO_PAGE_URL_PL = "/biogis-i-wizualizacja-danych"

export const SAMPLES_SEARCH_PAGE_URL_EN = "/samples-search"
export const SAMPLES_SEARCH_PAGE_URL_PL = "/wyszukiwarka-prob"

export const BIBLIOGRAPHY_SEARCH_PAGE_URL_EN = "/bibliography-search-engine"
export const BIBLIOGRAPHY_SEARCH_PAGE_URL_PL = "/wyszukiwarka-bibliografii"

export const SEARCH_PAGE_URL_EN = "/search-engine"
export const SEARCH_PAGE_URL_PL = "/wyszukiwarka"

export const USERS_ROLES_PAGE_URL_EN = "/user-roles"
export const USERS_ROLES_PAGE_URL_PL = "/role-uzytkownikow"

export const CONTACT_PAGE_URL_EN = "/contact"
export const CONTACT_PAGE_URL_PL = "/kontakt"

export const HOW_TO_USE_PAGE_URL_EN = "/how-to-use"
export const HOW_TO_USE_PAGE_URL_PL = "/jak-korzystac"

export const ABOUT_US_PAGE_URL_EN = "/about-us"
export const ABOUT_US_PAGE_URL_PL = "/o-nas"

export const ACCESSIBILITY_PAGE_URL_EN = "/accessibility-statement"
export const ACCESSIBILITY_PAGE_URL_PL = "/deklaracja-dostepnosci"

export const GDPR_PAGE_URL_EN = "/gdpr"
export const GDPR_PAGE_URL_PL = "/klauzula-rodo"

export const REGULATIONS_PAGE_URL_EN = "/regulations"
export const REGULATIONS_PAGE_URL_PL = "/regulamin"

export const PRIVACY_POLICY_PAGE_URL_EN = "/privacy-policy"
export const PRIVACY_POLICY_PAGE_URL_PL = "/polityka-prywatnosci"

export const MY_MAPS_PAGE_URL_EN = "/my-maps"
export const MY_MAPS_PAGE_URL_PL = "/moje-mapy"

export const PROTECTED_AREA_PAGE_URL_EN = "/protected-areas"
export const PROTECTED_AREA_PAGE_URL_PL = "/obszary-chronione"

export const PROTECTED_TAXON_PAGE_URL_EN = "/protected-taxons"
export const PROTECTED_TAXON_PAGE_URL_PL = "/zasoby-chronione"

export const SYSTEMATICS_PAGE_URL_EN = "/systematic-groups"
export const SYSTEMATICS_PAGE_URL_PL = "/grupy-systematyczne"

export const COMPARATOR_PAGE_URL_EN = "/image-comparator"
export const COMPARATOR_PAGE_URL_PL = "/porownanie-obrazow"

export const COLLECTION_PAGE_URL_EN = "/collections"
export const COLLECTION_PAGE_URL_PL = "/kolekcje"

export const TEAM_PAGE_URL_EN = "/my-teams"
export const TEAM_PAGE_URL_PL = "/moje-zespoly"
export const TEAM_INVITATION_ACCEPTED_PAGE = "/team/invitation/:hash"
export const TEAM_APPLICATION_PAGE = "/team/application/"

export const PROJECTS_PAGE_URL_EN = "/my-observations"
export const PROJECTS_PAGE_URL_PL = "/moje-obserwacje"

export const MY_ALBUM_PAGE_URL_EN = "/my-album"
export const MY_ALBUM_PAGE_URL_PL = "/moj-album"

export const PROFILE_PAGE_URL_EN = "/profile"
export const PROFILE_PAGE_URL_PL = "/profil"

export const REGISTER_PAGE_URL_EN = "/register"
export const REGISTER_PAGE_URL_PL = "/rejestracja"

export const LOGIN_PAGE_URL_EN = "/login"
export const LOGIN_PAGE_URL_PL = "/logowanie"

export const REPORTS_PAGE_URL_EN = "/reports-and-statistics"
export const REPORTS_PAGE_URL_PL = "/raporty-i-statystyki"

export const ICONDB_PAGE_URL_EN = "/multimedia-search-engine"
export const ICONDB_PAGE_URL_PL = "/wyszukiwarka-multimediow"

export const TAXONDB_PAGE_URL_EN = "/specimens-search-engine"
export const TAXONDB_PAGE_URL_PL = "/wyszukiwarka-okazow"

export const SITEMAP_PAGE_URL_PL = "/mapa-strony"
export const SITEMAP_PAGE_URL_EN = "/site-map"

export const EDUCATION_MATERIALS_PAGE_URL_PL = "/materiały-edukacyjne"
export const EDUCATION_MATERIALS_PAGE_URL_EN = "/educational-materials"

export const TEAM_LEADERS_PAGE_URL_PL = "/liderzy-zespolow"
export const TEAM_LEADERS_PAGE_URL_EN = "/team-leaders"

export const CREATE_RESOURCE_PAGE_URL_EN = "/create-resource"
export const CREATE_RESOURCE_PAGE_URL_PL = "/stworz-zasob"

export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_TIME_FORMAT = 'HH:mm';
export const MOMENT_DATETIME_FORMAT = `${MOMENT_DATE_FORMAT} ${MOMENT_TIME_FORMAT}`;

export const ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL = 'arrangement-tools-arrangement-panel'
export const ARRANGEMENT_TOOLS_SEARCH_PANEL = 'arrangement-tools-search-panel'
export const ARRANGEMENT_TOOLS_ACTIVE_VISUALIZATION = 'arrangement-tools-active-visualization'
export const ARRANGEMENT_BY_POINTS = "ARRANGEMENT_BY_POINTS"
export const ARRANGEMENT_CARTOGRAM = "ARRANGEMENT_CARTOGRAM"
export const ARRANGEMENT_TOOLS_SEARCH_ON_INIT = 'arrangement-tools-search-on-init'

export const MAP_TOOLS_ACTIVE_VISUALIZATION = 'map-tools-active-visualization'

export const MAP_TOOLS_VISUALIZATION_1 = "MAP_TOOLS_VISUALIZATION_1"
export const MAP_TOOLS_VISUALIZATION_2 = "MAP_TOOLS_VISUALIZATION_2"
export const MAP_TOOLS_VISUALIZATION_3 = "MAP_TOOLS_VISUALIZATION_3"
export const MAP_TOOLS_VISUALIZATION_4 = "MAP_TOOLS_VISUALIZATION_4"
export const MAP_TOOLS_VISUALIZATION_5 = "MAP_TOOLS_VISUALIZATION_5"
export const MAP_TOOLS_VISUALIZATION_6 = "MAP_TOOLS_VISUALIZATION_6"
export const MAP_TOOLS_VISUALIZATION_7 = "MAP_TOOLS_VISUALIZATION_7"

export const MAP_TOOLS_VISUALIZATION_RELATIONS = {
  MAP_TOOLS_VISUALIZATION_1: {
    name: 'position-map',
    url: {
      en: ARRANGEMENT_PAGE_URL_EN,
      pl: ARRANGEMENT_PAGE_URL_PL,
    },
  },
  MAP_TOOLS_VISUALIZATION_2: {
    name: 'area-map',
    url: {
      en: AREA_MAP_PAGE_URL_EN,
      pl: AREA_MAP_PAGE_URL_PL,
    },
  },
  MAP_TOOLS_VISUALIZATION_3: {
    name: 'clustering',
    url: {
      en: CLUSTERING_PAGE_URL_EN,
      pl: CLUSTERING_PAGE_URL_PL,
    },
  },
  MAP_TOOLS_VISUALIZATION_4: {
    name: 'cartogram',
    url: {
      en: CHOROPLETH_PAGE_URL_EN,
      pl: CHOROPLETH_PAGE_URL_PL,
    },
  },
  MAP_TOOLS_VISUALIZATION_5: {
    name: 'cartodiagram',
    url: {
      en: CARTODIAGRAM_PAGE_URL_EN,
      pl: CARTODIAGRAM_PAGE_URL_PL,
    },
  },
  MAP_TOOLS_VISUALIZATION_6: {
    name: 'taxons-grouping',
    url: {
      en: GROUPING_PAGE_URL_EN,
      pl: GROUPING_PAGE_URL_PL,
    },
  },
  MAP_TOOLS_VISUALIZATION_7: {
    name: 'historical-analysis-map',
    url: {
      en: TIMELAPSE_PAGE_URL_EN,
      pl: TIMELAPSE_PAGE_URL_PL,
    },
  },
}

export const MAP_OPENED_TOOL_VISUALIZATION_DETAILS = "VISUALIZATION_DETAILS"
export const MAP_OPENED_TOOL_SEARCH_FORM = "SEARCH_FORM"
export const MAP_OPENED_TOOL_DRAWINGS_STYLE = "DRAWINGS_STYLE"
export const MAP_OPENED_TOOL_SAVE_AND_LOAD = "SAVE_AND_LOAD"
export const MAP_OPENED_TOOL_PREDEFINED_RESOURCES = "PREDEFINED_RESOURCES"
export const MAP_OPENED_TOOL_OBSERVATIONS = "OBSERVATIONS"
export const MAP_OPENED_MANAGE_MAPTOOLS = "MANAGE_MAPTOOLS"
export const MAP_OPENED_MANAGE_QUICKSAVE = "QUICKSAVE"
export const MAP_OPENED_MANAGE_PERSONALIZATION = "PERSONALIZATION"

export const DEFAULT_PAGINATION_PER_PAGE_SET = [ 20, 50, 100, 200, 500 ];
export const DEFAULT_PAGINATION_PER_PAGE = 20;
